<template>
  <div class="cardSellView">
    <!--选择卡种-->
    <transition name="chooseCard">
      <div class="chooseCard-container content-index">
        <div class="chooseCard">
          <div style="padding:20px 0 0 20px">
            <el-page-header
                style="padding:10px"
                @back="$router.go(-1)"
                content="办卡(选择卡种)"
            ></el-page-header>
          </div>
          <!--          <div class="topView">-->
          <!--            <h3 style="margin-top:15px;font-size:20px">选择卡种</h3>-->
          <!--          </div>-->
          <div
              v-if="cardList && cardList.length != 0"
              class="cardTypeList listView"
              v-loading="cardLoading"
              element-loading-text="加载会员卡中"
          >
            <!-- 卡片部分 -->
            <div
                class="card-item"
                v-for="(card, index) in cardList"
                :key="index"
                @click="chooseCard(card, index)"
                :style="
                isActive == index
                  ? 'border: 3px solid #3370ff'
                  : 'border: 3px solid #fff'
              "
            >
              <div class="card-box">
                <div class="card-name">{{ card.cardName }}</div>
                <div
                    class="card-text"
                    v-for="(benefits, benefitsIndex) in card.memberBenefitsList"
                    :key="benefitsIndex"
                >
                  {{ benefitsIndex + 1 }}. {{ benefits.benefitsName }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="hint-box">
            <div class="hint-main">
              <img src="../../assets/img/ze-vip-card-o.png" alt=""/>
              <dir class="hint-text">
                您还没有配置会员卡卡种，请前往
                <el-button
                    type="text"
                    @click="cardConfiguration"
                >卡配置
                </el-button
                >
                配置后再使用
              </dir>
            </div>
          </div>
        </div>

        <!-- 表单部分 -->
        <div class="from-container">
          <h3>请填写用户信息</h3>
          <div>
            <el-form
                :model="applyCardForm"
                :rules="rules"
                ref="ruleForm"
                label-width="150px"
                class="demo-ruleForm"
            >
              <el-form-item label="请输入手机号" prop="phone1">
                <el-input
                    v-model="applyCardForm.phone1"
                    type="text"
                    @paste.native.capture.prevent="handlePaste"
                ></el-input>
              </el-form-item>
              <el-form-item label="请再次输入手机号" prop="phone">
                <el-input
                    v-model="applyCardForm.phone"
                    @paste.native.capture.prevent="handlePaste"
                ></el-input>
              </el-form-item>
              <el-form-item label="请输入姓名" prop="realName">
                <el-input
                    v-model.trim="applyCardForm.realName"
                    :disabled="userId != ''"
                    @paste.native.capture.prevent="handlePaste"
                ></el-input>
              </el-form-item>
              <el-form-item label="请输入性别" prop="gender">
                <el-select
                    v-model="applyCardForm.gender"
                    placeholder="请选择性别"
                    :disabled="userId != ''"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="生日">
                <el-date-picker
                    v-model="applyCardForm.birthday"
                    type="date"
                    placeholder="请选择生日"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    :disabled="userId != ''"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="请输入卡号">
                <el-input
                    v-model="applyCardForm.cardNo"
                    :disabled="userId != ''"
                    @paste.native.capture.prevent="handlePaste"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="Recharge">
              <el-button type="primary" @click="goToRecharge">
                前往结算
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--支付页面-->
    <transition name="payFullscreen">
      <div class="cardCardCheckoutView" v-if="q">
        <div class="leftView" style="margin-right: 480px;">
          <div class="topView">
            <label>支付</label>
            <button class="btn-back" @click="handleCloseCardPay"></button>
          </div>
          <div class="contentView listView" style="height: 620px;">
            <div class="orderInfoView">
              <div class="timeView">
                办卡时间：{{ $moment().format("YYYY-MM-DD HH:mm") }}
              </div>
              <div class="cardView">
                <label>卡片名称：{{ this.applyCardForm.cardName }}</label>
              </div>
              <div class="toPayView">
                <div class="amtItem subItem">
                  <label class="label-name">
                    <span>办卡应收</span>
                  </label>
                  <label class="label-amt">
                    <span>¥ {{ payPrice }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="paymentView">
              <div class="tView">支付方式</div>
              <div class="listView">
                <div
                    class="paymentItem listItem"
                    v-for="(item, index) in pay_ways"
                    @click="handlePayWay(item)"
                    :key="index"
                >
                  <div class="iconView">
                    <img :src="item.ico" v-if="item.label != 12"/>
                    <span
                        v-else
                        class="iconfont icon-31saoma"
                        style="font-size:40px"
                    ></span>
                  </div>
                  <div class="textView overflowText">{{ item.name }}</div>
                  <div class="selectView" v-if="payWay === item.label">
                    <img
                        src="../../assets/navImages/Def_Icon_Select_Black.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="btnView">
            <div class="btn-settle" v-if="payWay !== 6" @click="checkout">
              结账
            </div>
          </div> -->
        </div>
        <div class="rightView" style="width: 480px;">
          <div class="defImgView">
            <img src="../../assets/navImages/Def_Bc_OrderCheckout_Right.png"/>
            <p>请选择结账方式</p>
          </div>
          <transition name="paidFullscreen">
            <div
                id="qrCodePayPopView"
                class="popView"
                style="width: 480px;"
                v-loading="qrCodePayView"
                :element-loading-text="qrCodePayViewName"
                v-if="payWayView"
            >
              <div class="popView-bcView" @click="handleClosePayWay"></div>
              <div
                  class="popView-contentView"
                  style="width: 450px; top: 0; right: 0; overflow: auto;"
              >
                <div class="qrCodePayView" style="height: 794px;">
                  <div class="contentView" style="height: 513px;">
                    <div class="amtView">
                      ¥
                      <span>{{ payPrice }}</span>
                    </div>
                    <div class="imgView">
                      <!-- <img src="https://www.shouqianba.com/img/logo.svg"/> -->
                    </div>
                    <div class="qrCodeInputView">
                      <el-input
                          class="input-scanPayCode"
                          placeholder="请扫描或输入付款码"
                          autocomplete="off"
                          ref="pay"
                         v-model="payVal"
                      type="number"
                          autofocus="autofocus"
                      ></el-input>
                      <el-button @click="applyCard" type="primary">
                        收款
                      </el-button>
                    </div>
                    <div class="tipView">
                      请使用支付宝/微信扫码支付
                      <p>支付过程中，请不要关闭该二维码！</p>
                    </div>
                  </div>
                </div>
                <!-- 关闭收款右侧页面 -->
                <button class="btn-close" @click="payWayView = false"></button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <!-- 前往结算的弹出层 -->
    <el-dialog
        title="确认办卡信息"
        font-weight:
        append-to-body
        :visible.sync="dialogVisible"
        center
        custom-class="cardDialog"
    >
      <div style="color: #303133;">
        <div class="apply_card_info">
          <div>
            <div class="bkxx">
              <div style="padding:2px 0">
                <span class="userInfo_1_1">姓 名:</span>
                <span>{{ applyCardForm.realName }}</span>
              </div>
              <div style="padding:2px 0">
                <span class="userInfo_1_1">手机号:</span>
                <span style="color: #860404;">{{ applyCardForm.phone }}</span>
              </div>
              <div style="padding:2px 0">
                <span class="bkmc">卡种名称:</span>
                <span>{{ applyCardForm.cardName }}</span>
              </div>
              <div style="padding:2px 0">
                <span class="bkje">办卡金额:</span>
                <span style="color: #860404;"
                >{{ applyCardForm.cardPrice }}<a>元</a></span
                >
              </div>
              <div class="quanyixq" style="display:flex">
                <span>权益详情:</span>
                <div style="padding-left:10px">
                  <span
                      style="padding:2px 0"
                      v-for="(benefits,
                    benefitsIndex) in cardInfo.memberBenefitsList"
                      :key="benefitsIndex"
                  >
                    <p class="benefits">
                      {{ benefitsIndex + 1 }}、{{ benefits.benefitsName }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="upgrade_info" v-if="upgradeFlag == 1">
          <div>
            <span>会员剩余天数:</span>
            <span class="bkmony xxsm"> {{ lastDays }}</span>
          </div>
          <div>
            <span>需补差价:</span>
            <span class="bkmony xxsm"> {{ upgradePrice }}</span
            ><br/>
          </div>
        </div>
        <div style="text-align: center; margin-top: 10px;">
          <span style="margin-right: 10px; color: red; vertical-align: middle;">
            *
          </span>
          <span class="xxsm">请仔细阅读确认信息以免办错卡种,造成损失</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goToSettlement">确定办卡</el-button>
        <el-button @click="cancelApplyCard">取消办卡</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import FullScreenDialog from "@/components/FullScreenDialog";
// import DateSelect from "@/components/DateSelect";
// import CardCheckoutView from "@/view/card/components/cardCheckoutView";
import {
  applyCard,
  payOrder,
  payCardQuery,
  memberCardUpgrade,
  getUserInfoForApplyCard,
  memberCardUpgradeCheck,
} from "@/api/member/user";
import {getCardList} from "@/api/member/card";
import {isMobile, isPhone} from "@/utils/validate";
import options from "../../assets/js/regions";

export default {
  name: "index",
  components: {
    // FullScreenDialog,
    // DateSelect,
    // CardCheckoutView,
  },
  data() {
    let validatePass1 = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式不对"));
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式不对"));
      } else if (value !== this.applyCardForm.phone1) {
        callback(new Error("两次输入手机号不一致!"));
      } else {
        this.getUser(value);
        callback();
      }
    };
    return {
      dialogVisible: false,
      isActive: 0,
      applyCardView: false,
      rules: {
        //验证规则
        phone1: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {
            required: true,
            validator: validatePass1,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {required: true, message: "请再次输入手机号", trigger: "blur"},
          {
            validator: validatePass2,
            trigger: ["blur", "change"],
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
      },
      q: false,
      cardList: [],
      applyCardForm: {
        userType: "",
        phone: "",
        phone1: "",
        cardNo: "",
        realName: "",
        gender: "",
        payPrice: 0,
        payCode: "",
        cardId: 0,
        cardName: "",
        cardPrice: "",
        channel: 1,
        payWay: 12,
        payWayName: "扫码支付",
        source: 1,
        birthday: "",
      },
      payPrice: "0",
      cardLoading: false,
      cardType: 1,
      cardId: 0,
      selectedOptions: [], //存放默认值
      options: options, //存放城市数据也就是显示出来
      qrCodePayView: false,
      qrCodePayViewName: "收款中...",
      payWay: "",
      payWayView: false,
      // 支付方式
      pay_ways: [
        {
          name: "扫码支付",
          label: 12,
        },
      ],
      // chooseView: true,
      phone: "",
      userInfo: {},
      // 支付二维码
      payVal: "",
      myInterval: null,
      shouInterval: null,
      userRecordId: "",
      result: {
        cardNo: "",
        realName: "",
        payPrice: 0,
        cardName: "",
        phone: "",
        payWayName: "扫码支付",
      },
      userId: "",
      outTradeNo: "",
      cardInfo: {},
      // 升级标识
      upgradeFlag: 0,
      lastDays: "",
      upgradePrice: "",
    };
  },
  created() {
    // 判断传参手机号
    if (this.$route.query.phone) {
      this.phone = this.$route.query.phone;
      this.applyCardForm.phone1 = this.$route.query.phone;
      this.applyCardForm.phone = this.$route.query.phone;
      this.getUser(this.applyCardForm.phone);
    }
    this.getCardList();
  },
  watch: {
    q: {
      handler: function (value) {
        if (value == false) {
          clearInterval(this.myInterval);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cardConfiguration() {
      this.$router.push("more/cardSet");
    },
    handlePaste() {
    }, //禁止粘贴
    // 结账
    checkout() {
      console.log(this.payWay);
      if (!this.payWay) {
        this.$message.error("请选择支付方式");
        return false;
      }
      this.$router.push({name: "applyCardSuccess"});
    },
    //初始化权益卡列表
    getCardList: function () {
      this.cardLoading = true;
      const params = new URLSearchParams();
      params.append("cardType", this.cardType);
      params.append("cardId", this.cardId);
      getCardList(params).then((response) => {
        this.cardList = response.data.data;
        this.cardLoading = false;
        let card = this.cardList[this.isActive];
        this.payPrice = this.applyCardForm.cardPrice = card.cardPrice;
        this.applyCardForm.cardId = card.cardId;
        this.applyCardForm.cardName = card.cardName;
      });
    },
    // 前往付款
    goToRecharge() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.cardInfo = this.cardList[this.isActive];
          console.log(this.cardInfo);
          try {
            // 先校验
            const params = new URLSearchParams();
            params.append("phone", this.applyCardForm.phone);
            params.append("cardId", this.cardInfo.cardId);
            let res = await memberCardUpgradeCheck(params);
            if (res.data.code === 0) {
              if (res.data.data.isUpgrade == 1) {
                // 说明是升级卡种
                this.upgradeFlag = 1;
                this.lastDays = res.data.data.lastDays;
                this.payPrice = this.applyCardForm.payPrice = this.upgradePrice =
                    res.data.data.upgradePrice;
              }
              this.applyCardForm.cardPrice = this.cardInfo.cardPrice;
              this.dialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          return false;
        }
      });
    },
    //前往结算
    goToSettlement() {
      this.q = true;
      this.dialogVisible = false;
    },
    // 取消办卡
    cancelApplyCard() {
      this.dialogVisible = false;
      this.upgradeFlag = 0;
      this.upgradePrice = "";
      this.lastDays = "";
    },
    cleanPhone() {
      this.phone = "";
    },
    // 选择卡
    chooseCard(card, index) {
      this.payPrice = card.cardPrice;
      this.applyCardForm.cardName = card.cardName;
      this.applyCardForm.cardId = card.cardId;
      this.isActive = index;
    },
    //输入手机号后查询用户信息
    getUser(phone) {
      let that = this;
      const params = new URLSearchParams();
      params.append("phone", phone);
      getUserInfoForApplyCard(params).then((response) => {
        console.log(response);
        // 说明是存在的用户
        if (response.data.data.userId !== null) {
          that.userId = response.data.data.userId;
          that.applyCardForm.realName = response.data.data.realName;
          that.applyCardForm.gender = response.data.data.gender;
          that.applyCardForm.birthday = response.data.data.birthday;
          that.applyCardForm.cardNo =
              response.data.data.memberUser != null
                  ? response.data.data.memberUser.cardNo
                  : "";
        } else {
          that.userId = "";
          that.applyCardForm.cardNo = that.applyCardForm.phone;
        }
      });
    },
    // 关闭会员卡支付页面
    handleCloseCardPay() {
      this.q = false;
      this.payWay = "";
      this.payWayView = false;
    },
    // 处理点击支付
    handlePayWay(item) {
      // console.log(item.label)
      this.payWay = item.label;
      this.applyCardForm.payWay = this.payWay;
      if (item.label === 6) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
      if (item.label === 12) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
    },
    // 关闭支付方式
    handleClosePayWay() {
      this.payWayView = false;
    },
    applyCard() {
      if (!this.payVal) {
        this.$message.error("请输入付款码");
        return false;
      }
      this.applyCardForm.payCode = this.payVal;
      this.qrCodePayView = true;
      console.log(this.applyCardForm);
      // 升级卡种
      if (this.upgradeFlag == 1) {
        memberCardUpgrade(this.applyCardForm)
            .then((response) => {
              console.log(response);
              if (response.data.code === 0) {
                this.result = response.data.data;
                if (response.data.data) {
                  this.userRecordId = response.data.data.userRecordId;
                  this.outTradeNo = response.data.data.outTradeNo;
                  // 调用支付接口
                  this.payOrder();
                }
              } else {
                this.qrCodePayView = false;
                //支付失败
                this.$message.error(response.data.msg);
              }
            })
            .catch((e) => {
              console.error(e);
              this.qrCodePayView = false;
            });
      } else {
        applyCard(this.applyCardForm).then((response) => {
          console.log(response);
          if (response.data.code === 0) {
            this.result = response.data.data;
            if (response.data.data) {
              this.userRecordId = response.data.data.userRecordId;
              this.outTradeNo = response.data.data.outTradeNo;
              // 调用支付接口
              this.payOrder();
            }
          } else {
            this.qrCodePayView = false;
            //支付失败
            this.$message.error(response.data.msg);
          }
        });
      }
    },
    payOrder() {
      let payWay = this.applyCardForm.payWay;
      let userRecordId = this.userRecordId;
      let authCode = this.applyCardForm.payCode;
      let data = {
        payWay,
        userRecordId,
        authCode,
      };
      payOrder(data)
          .then((response) => {
            console.log(response);
            // this.isPrint = false;
            if (response.data.code === 0) {
              // 只有微信 支付宝有回调
              if (payWay === 1 || payWay === 2 || payWay === 6 || payWay == 12) {
                this.outTradeNo = response.data.data.outOrderNo;
                this.queryPayResult(this.outTradeNo);
                this.myInterval = setInterval(() => {
                  this.queryPayResult(this.outTradeNo); //调用接口的方法
                }, 3000);
              } else {
                this.commonPaySuccess();
              }
            } else {
              if (payWay === 1 || payWay === 2 || payWay === 6 || payWay == 12) {
                clearInterval(this.shouInterval);
                // 需要用户支付
                if (response.data.data) {
                  // 需要用户支付
                  if (response.data.data.subCode === "WAITING_PAYMENT") {
                    this.outTradeNo = response.data.data.outOrderNo;
                    this.myInterval = setInterval(() => {
                      this.queryPayResult(this.outTradeNo); //调用接口的方法
                    }, 3000);
                  } else {
                    this.payLoading = false;
                    this.$message.error(response.data.data.subMsg);
                    this.closePayModal();
                    this.commonPayFailed();
                  }
                } else {
                  if (response.data.data) {
                    this.$message.error(response.data.data.subMsg);
                  } else {
                    this.$message.error(response.data.msg);
                  }

                  this.payLoading = false;
                  this.closePayModal();
                  this.commonPayFailed();
                }
              } else {
                this.$message.error(response.data.data.subMsg);
                this.commonPayFailed();
              }
            }
          })
          .catch(() => {
            // 要进行跳转到当前未支付的订单
            this.closePayModal();
            // this.commonPayFailed()
          });
      if (this.applyCardForm.payWay === 6 || this.applyCardForm.payWay === 12) {
        this.qrCodePayViewName = "等待用户输入密码...";
      }
    },
    // 查询支付结果
    queryPayResult(outTradeNo) {
      // const params = new URLSearchParams();
      // params.append("payWay", this.applyCardForm.payWay);
      // params.append("outTradeNo", this.outTradeNo);
      payCardQuery({
        payWay:this.applyCardForm.payWay,
        outTradeNo:outTradeNo
      })
          .then((response) => {
            console.log(response);
            // 微信
            if (response.data.code == 0) {
              if (response.data.data.subCode === "SUCCESS") {
                clearInterval(this.shouInterval);
                this.commonPaySuccess();
              } else if (response.data.data.subCode === "WAITING_PAYMENT") {
                this.qrCodePayViewName = "等待用户输入密码";
              } else {
                this.$message.error(response.data.data.subMsg);
                this.applyCardForm.payCode = null;
                this.closePayModal();
                this.commonPayFailed();
              }
            } else {
              this.$message.error(response.data.msg);
              this.closePayModal();
              this.commonPayFailed();
            }
          })
          .catch(() => {
            this.closePayModal();
            this.commonPayFailed();
          });
    },
    // 公共的
    commonPaySuccess() {
      this.qrCodePayView = false;
      this.$message({
        message: "办卡成功!",
        type: "success",
      });
      clearInterval(this.myInterval);
      this.$router.push({
        name: "applyCardSuccess",
        query: {
          userRecordId: this.userRecordId,
        },
      });
    },
    commonPayFailed() {
      this.$router.push({
        path: "/home/more/applyCardList",
      });
    },
    closePayModal() {
      this.qrCodePayView = false;
      clearInterval(this.myInterval);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep input[type="number"]{
    -moz-appearance: textfield;
}
.el-button {
  // background-color: #3370FF;
}

// 去掉返回按钮
// /deep/ .el-page-header__left .el-icon-back {
//   font-size: 0px;
//   margin-right: 0;
// }

// /deep/ .el-page-header__left {
//   margin-right: 0px;
// }

// /deep/ .el-page-header__left::after {
//   content: none;
// }

// 选择卡片
.active {
  border: 3px solid #3370ff;
}

/deep/ .cardDialog {
  border-radius: 5px !important;
}

.userInfo {
  padding-left: 50%;

  div:not(:first-child) {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
  }

  div:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
  }
}

.cardInfo {
  padding-left: 25%;

  div:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
  }

  div:not(:first-child) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }
}

.chooseCard-container {
  display: flex;
  overflow: auto;

  .from-container {
    flex: 4.7;
    border-left: 1px solid #eff2f6;
    padding-left: 20px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    > div {
      width: 50%;
      margin-top: 25px;

      .el-select {
        width: 100%;
      }

      .el-date-editor {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 20px;
      }

      .Recharge {
        display: flex;
        justify-content: center;

        .el-button {
          width: 40%;
        }
      }
    }
  }
}

.cardSellView {
  background: #fff;
  padding-left: 10px;
  position: relative;
  z-index: 200;

  > .topView {
    padding: 17.5px 20px;
    overflow: hidden;

    > .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }
  }

  > .searchView {
    position: relative;
    width: 360px;
    height: 170px;
    left: 0;
    right: 0;
    margin: 15% auto 0 auto;
    -webkit-transition: -webkit-transform 0.7s, background 0.7s, font-size 0.7s;

    > .imgView {
      text-align: center;

      > img {
        width: 262px;
        height: 86px;
      }
    }

    > .inputView {
      display: flex;
      margin-top: 30px;
      background: #f8f8f8;
      border-radius: 6px;
      overflow: hidden;
      height: 44px;

      > input {
        flex: 1;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 24px;
        border: none;
        background: #f8f8f8;
      }

      > .btn-search.hover {
        background: #3370ff;
      }

      > .btn-search {
        width: 65px;
        height: 100%;
        background: #eee;
        font-size: 16px;
        color: #fff;
        padding: 0;
        text-align: center;
        border-radius: 0;
      }

      > .btn-clean {
        width: 30px;
        height: 100%;
        background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_InputClean.png) left center / 20px no-repeat;
      }
    }

    > .btn-face {
      position: absolute;
      width: 44px;
      height: 44px;
      background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AuthFace.png) center / 28px no-repeat;
      top: 120px;
      right: -60px;
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// button {
// 	background: transparent;
// 	border: none;
// 	outline: none;
// 	box-sizing: border-box;
// 	cursor: pointer;
// }

.cardBuyView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  font-family: PingFangSC-Regular;

  > .topView {
    background: #fff;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.01);
  }

  > .sellInfoView .inputView {
    display: flex;
    height: 44px;
    padding: 8px 10px;
    line-height: 28px;
    background: #f8f8f8;
    border-radius: 6px;
    font-size: 15px;
    margin-bottom: 12px;

    /* margin-bottom: 0; */
    > input {
      flex: 1;
      background: #f8f8f8;
      text-align: right;
      font-size: 15px;
      color: #333;
      line-height: 28px;
      border: none;
      outline: none;
      padding: 0;
    }

    > input.input-payAmt[disabled] {
      color: #ff5e56;
      opacity: 1;
    }
  }

  > .sellInfoView {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    > .cusView {
      width: 380px;
      min-width: 380px;
      padding: 0 55px 35px 55px;
      border-right: 0.5px solid rgba(220, 220, 220, 0.7);
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
      }

      > .cardView {
        position: relative;
        width: 270px;
        height: 170px;
        margin-bottom: 15px;

        > img {
          width: 270px;
          height: 170px;
          border-radius: 6px;
        }

        > .cardNameView {
          position: absolute;
          left: 15px;
          top: 15px;
          line-height: 25px;
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #fff;
        }
      }

      > .infoView {
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);

        > div {
          display: flex;
          margin-bottom: 12px;
          height: 44px;
          padding: 8px 10px;
          line-height: 28px;
          background: #f8f8f8;
          border-radius: 6px;
          font-size: 15px;

          label {
            width: 90px;
            color: #666;
          }
        }

        > div.sexView {
          background: #fff;
          margin: -7px 0 5px 0;

          > div {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
          }
        }

        > div.dateView {
          background: #fff;

          > div {
            width: 160px;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
          }
        }
      }
    }

    > .accView {
      flex: 1;
      padding: 0 0 15px 0;
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 0 55px;
      }

      > .buyInfoView {
        padding: 0 40px;
        padding-bottom: 35px;

        > .amtView {
          padding-right: 20px;
        }

        > .btnView {
          text-align: center;
          margin-top: 12px;

          > button {
            width: 340px;
            height: 44px;
            background: #3370ff;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }

  > .topupInfoView .inputView {
    display: flex;
    height: 44px;
    padding: 8px 10px;
    line-height: 28px;
    background: #f8f8f8;
    border-radius: 6px;
    font-size: 15px;
    margin-bottom: 12px;

    > label {
      width: 90px;
      color: #666;
    }

    > input {
      flex: 1;
      background: #f8f8f8;
      text-align: right;
      font-size: 15px;
      color: #333;
      line-height: 28px;
      border: none;
      outline: none;
      padding: 0;
    }

    > input.input-payAmt[disabled] {
      color: #ff5e56;
      opacity: 1;
    }
  }

  > .topupInfoView {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 25px;

    > .cusView {
      width: 380px;
      padding: 0 0 15px 0;
      border-right: 0.5px solid rgba(220, 220, 220, 0.7);
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 40%;

      > .tView {
        padding: 0 30px;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
      }

      > .cardView {
        position: relative;
        width: 270px;
        height: 170px;
        margin-bottom: 15px;

        > img {
          width: 270px;
          height: 170px;
          border-radius: 6px;
        }

        > .cardNameView {
          position: absolute;
          left: 15px;
          top: 15px;
          line-height: 25px;
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #fff;
        }
      }

      > .cardInfoView {
        padding: 25px 30px;
        line-height: 30px;

        > .baseInfoView {
          padding: 0 40px 25px 40px;

          > div {
            display: flex;
            font-size: 15px;

            > label:first-child {
              color: #999;
            }

            > label:last-child {
              flex: 1;
              color: #333;
              text-align: right;
            }
          }
        }

        > .accInfoView {
          border-top: 0.5px dashed rgba(220, 220, 220, 0.7);

          > .tView {
            padding: 15px 0 10px;
            line-height: 25px;
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;

            > span {
              font-family: PingFangSC-Regular;
              font-size: 13px;
              color: #999;
              margin-left: 12px;
            }
          }

          > .listView {
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 25px;

            > .accItem {
              display: flex;
              line-height: 35px;

              > label:first-child {
                color: #999;
              }

              > label:last-child {
                flex: 1;
                color: #333;
                text-align: right;
              }
            }
          }
        }
      }
    }

    > .topupInfoView {
      flex: 1;
      padding: 0 55px 15px 55px;
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 0 55px;
      }

      > .infoView {
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 25px;

        > .amtView {
          padding-right: 20px;
        }

        > .editCardView {
          > .editItem {
            display: flex;
            padding: 8px 10px;
            line-height: 28px;

            > label {
              width: 150px;
              font-size: 15px;
              color: #666;
            }

            > .btnView {
              flex: 1;
              display: flex;
              padding: 4px 15px;
              background: #fff;

              > button {
                flex: 1;
                text-align: left;
                padding-left: 32px;
                font-size: 15px;
                color: #333;
                margin: 0;
                background: #fff url(https://static.bokao2o.com/wisdomCashier/images/Def_Icon_Choose_N.png) left center / 28px no-repeat;
              }

              > button.select {
                background: #fff url(https://static.bokao2o.com/wisdomCashier/images/Def_Icon_Choose_S.png) left center / 28px no-repeat;
              }
            }
          }
        }

        > .btnView {
          text-align: center;
          margin-top: 12px;

          > button {
            width: 340px;
            height: 44px;
            background: #3370ff;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }
}

//动画过程
.cardDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.cardDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.cardDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.cardDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  z-index: 100;

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      > label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        border: none;
        outline: none;
        background: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png) no-repeat left center;
        background-size: 28px;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 2;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 87px;
            height: 87px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: rgba(66, 153, 255, 0.7);
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png) left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

p {
  margin: 0;
  padding: 0;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          margin-right: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png) center / 28px 28px no-repeat;
      border: none;
      outline: none;
    }
  }
}

//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: paidFullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes paidFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes paidFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.chooseCard {
  flex: 5;

  .hint-box {
    height: calc(100vh - 170px);
    position: relative;

    .hint-main {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(50vw - 100px);

      .hint-img {
      }

      .hint-text {
        color: #b2b2b2;
        font-size: 14px;
        padding: 0;
        margin: 0;
        width: 100%;

        .el-button {
          font-size: 14px;
        }
      }
    }
  }

  > .topView {
    padding-left: 20px;
  }

  > .cardTypeList {
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 15px;
    display: flex;
    flex-wrap: wrap;

    .card-item {
      border-radius: 5px;
      display: flex;
      width: 296px;
      margin: 0 10px 10px 0;
      box-sizing: border-box;
    }

    .card-box {
      width: 280px;
      height: 176px;
      background-image: url(../../assets/navImages/applycard.png);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
      overflow: hidden;
      margin: 5px;
      padding: 18px 15px 0;
      color: #fff;

      .card-name {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .card-text {
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
  }
}

//动画过程
.chooseCard-enter {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

.chooseCard-enter-active {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave-active {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

@keyframes chooseCard-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes chooseCard-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.kz1 {
  color: red;
}

.iphone {
  color: red;
}

.bkmony {
  color: red;
}

.quanyi {
  color: red;
}

.userInfo_1 {
  display: grid;
  justify-content: center;
  font-size: 15px;
  padding-left: 28px;
}

.userInfo_1_1 {
  margin-right: 10px;
  width: 64px;
  display: inline-block;
  text-align: right;
}

.bk1 {
  margin-right: 20px;
}

.bkmc {
  margin-right: 10px;
}

.bkje {
  margin-right: 10px;
}

.xxsm {
  font-weight: bold;
  font-size: 16px;
}

.apply_card_info {
  display: grid;
  justify-content: center;
  margin-top: 0;
  font-size: 15px;
}

.upgrade_info {
  display: grid;
  justify-content: center;
  margin-top: 20px;
  font-size: 15px;
}
</style>
